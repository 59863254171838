<template>
    <section v-show="visible" :class="mainClass" class="recommendations-container">
        <div class="heading">
            {{ title }}
        </div>
        <div v-show="description !== ''" class="highlight-description">
            {{ description }}
        </div>
        <div class="flex-carousel flex-carousel-with-cards small-cards">
            <div class="carousel-container">
                <div class="carousel-items slider-items">
                    <div class="carousel-item" v-for="(item, index) in items" :data-index="index" :id="getElemId(index)" :class="hide(index)">
                        <div class="card list-item-card">
                            <div class="card-header">
                                <a @click.middle="fireClick(item, index)" @click="fireClick(item, index)" :href="getLink(item)" data-tgev="event10" data-tgev-metric="npv" :data-tgev-order="index + 1" :data-tgev-label="getFilteredTitle(item.title)" :data-tgev-container="title.toLowerCase()">
                                    <img class="img-responsive main-icon" :alt="item.title" :title="item.title" :src="imgSrc(item, 'd150x100')" width="150" height="100">
                                </a>
                            </div>
                            <div class="card-body">
                                <div v-show="item.rating > 0" class="ratings">
                                    <div class="rating-box">
                                        <div class="background-stars">
                                            <span v-for="n in 5" class="icon-rating-star"></span>
                                        </div>
                                        <div class="overlay-stars-restrict" :style="ratingStyle(item)">
                                            <div class="overlay-stars-container">
                                                <span v-for="n in 5" class="icon-rating-star"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="number">({{ item.ratingCount }})</span>
                                </div>
                                <a v-show="item.rating == 0" :href="getCoralLink(item)" class="rate-now" data-tgev="event10" data-tgev-metric="ev" :data-tgev-order="index + 1" :data-tgev-label="getFilteredTitle(item.title)" :data-tgev-container="title.toLowerCase()">Rate now</a>
                                <a :href="getLink(item)" @click.middle="fireClick(item, index)" @click="fireClick(item, index)" data-tgev="event10" data-tgev-metric="ev" :data-tgev-order="index + 1" :data-tgev-label="getFilteredTitle(item.title)" :data-tgev-container="title.toLowerCase()">
                                    <span class="title">{{ item.title }}</span>
                                </a>
                            </div>
                            <div class="card-footer">
                                <span class="time float-left">{{ cookingTime(item) }}</span>
                                <a href="#" :data-fapi-id="item.id" :data-lead-image="imgSrc(item)" :data-title="item.title" :data-slug="item.slug"
                                   class="float-right save-recipe-link unfilled-heart"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="items.length > 4" class="carousel-navigation">
                    <button class="prev-button icon-pagination-arrow-prev" data-dir="prev"></button>
                    <button class="next-button icon-pagination-arrow-next" data-dir="next"></button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Breakpoints from '../../../js/utils/breakpoints';
import Cookie from '../../../js/utils/cookie';
import HorizontalSliders from "../../../js/horizontal-sliders";
import FlexCarousel from '../../../js/flex-carousel';
import Vidora from '../../services/vidora';
import {user} from '../../nlm';

const DESKTOP_ITEMS = 10;
const MOBILE_ITEMS = 10;
const TABLET_ITEMS = 10;

const DESKTOP_VISIBLE_ITEMS_NUMBER = 4;
const MOBILE_VISIBLE_ITEMS_NUMBER = 3;
const TABLET_VISIBLE_ITEMS_NUMBER = 4;

export default {
    data() {
        return {
            category: '/taste/display/recipe',
            contentLoading: true,
            description: '',
            groupId: 'vidora-recipe-recommendation',
            items: [],
            itemsNumber: 10,
            itemsVisibleNumber: 4,
            mainClass: 'highlight horizontal-slider',
            title: 'Recommended For You',
            userId: "",
            vidora: {},
            vidoraAPI: {},
        }
    },

    computed: {
        // Return true if the recommendation module has enough items to display or to show a placeholder container with a fix height while content is loading.
        visible() {
            return this.items.length >= 4 || this.contentLoading;
        },
    },

    created() {
        if (Breakpoints.isPhone()) {
            this.itemsVisibleNumber = MOBILE_VISIBLE_ITEMS_NUMBER;
            this.itemsNumber = MOBILE_ITEMS;
        }
        if (Breakpoints.isTablet()) {
            this.itemsVisibleNumber = TABLET_VISIBLE_ITEMS_NUMBER;
            this.itemsNumber = TABLET_ITEMS;
        }
        if (Breakpoints.isDesktop()) {
            this.itemsVisibleNumber = DESKTOP_VISIBLE_ITEMS_NUMBER;
            this.itemsNumber = DESKTOP_ITEMS;
        }
    },

    methods: {

        // Attach listeners to the slider
        attachListener() {
            if (Breakpoints.isPhone() || Breakpoints.isTablet()) {
                HorizontalSliders.attach('.recommendations-container .slider-items');
            }
            FlexCarousel.initCarousel($('.recommendations-container').find('.flex-carousel'));
        },

        // Return the cooking time formatted in hours and minutes
        cookingTime(item) {
            let totalTime = item.totalTime;
            if (totalTime < 1) {
                return "";
            }

            let hours = Math.floor(totalTime / 60);
            let minutes = totalTime % 60;
            if (hours === 0 && minutes > 0) {
                return minutes + "m";
            } else if (hours > 0 && minutes === 0) {
                return hours + "h";
            } else if (hours > 0 && minutes > 0) {
                return hours + "h " + minutes + "m";
            }
            return "";
        },

        // If errors, contentLoading is set to false
        errors() {
            this.contentLoading = false;
        },

        // Hide the item if the given index is higher than max item count config for desktop, mobile and table
        hide(index) {
            let hiddenClasses = [];
            if (index > this.itemsVisibleNumber) {
                hiddenClasses.push('hidden-xs');
                hiddenClasses.push('hidden-sm');
            }
            return hiddenClasses.join(' ');
        },

        // Return the image source url of a given item
        imgSrc(item, size) {
            if (typeof item.images === "undefined" || item.images.length === 0) {
                return "/assets/static/images/generic-placeholder.jpg";
            }

            if (size && size in item.images[0].sizes) {
                return item.images[0].sizes[size]
            }

            return item.images[0].url;
        },

        // Commenting these out for now because we're not actually using different sizes
        // // Return the sizes attribute
        // imgSizes() {
        //     return "(max-width: 999px) 150px, 100px";
        // },
        //
        // // Return the srcset value for a given item
        // imgSrcset(item) {
        //     return item.images[0].url + ' 150w, ' + item.images[0].url + ' 100w';
        // },

        // Fire a click event
        fireClick(item, index) {
            this.vidora.sendClick(item.entityType + ":" + item.id, this.groupId, index);
        },

        // Initialise Vidora Object
        initVidora: function (content) {
            let items = [];
            for (let i = 0; i < content.length; i++) {
                if (typeof content[i].images !== "undefined") {
                    items.push(content[i]);
                }
            }
            this.items = items.slice(0, this.itemsNumber);
            this.vidora = new Vidora(this.vidoraAPI, this.items, this.groupId, this.userId);
            this.contentLoading = false;

            return new Promise((resolve, reject) => {
                if(this.items.length > 0) {
                    resolve(true);
                } else {
                    reject("Not enough items returned");
                }
            });
        },

        // Get the data from Fapi Lite
        getFapiLiteData(data) {
            let query = this.getFapiLiteQuery(data);
            return new Promise((resolve, reject) => {
                $.ajax(query).done(function (content) {
                    resolve(content.items);
                }).fail(function(jqXHR) {
                    reject(jqXHR);
                });
            });
        },

        // Get data required to render the module
        getData () {
            let self = this;
            window.vidora.ready(function (api) {
                self.vidoraAPI = api;
                self.setUserId().then(value => {
                    return self.getRecommendations();
                }).then(data => {
                    return self.getFapiLiteData(data);
                }).then(content => {
                    return self.initVidora(content);
                }).then(result => {
                    self.attachListener()
                }).catch(errors => self.errors());
            });
        },

        // Return the query to contact lite fapi
        getFapiLiteQuery: function (data) {
            let ids = [];
            data.items.slice(0, this.itemsNumber).forEach(function (el) {
                ids.push(el.id);
            });

            return this.$root.fapi_lite_url + "contents/"+ ids.join(',') + "?sizes=d150x100";
        },

        // Return the rate now url
        getCoralLink(item) {
            return this.getLink(item) + '#coral_thread';
        },

        // Return the content url of an item
        getLink(item) {
            if (typeof item.webUrl !== "undefined") {
                return item.webUrl;
            }
            return '/' + item.entityType + '/' + item.slug + '/' + item.id;
        },

        // Return the id attribute, based on the index
        getElemId(index) {
            return 'slot-' + index;
        },

        // Get recommended content ids from Vidora
        getRecommendations() {
            let uri = this.getVidoraUri();
            return new Promise((resolve, reject) => {
                $.ajax(uri).done(function (data) {
                    resolve(data);
                }).fail(function(jqXHR) {
                    reject(jqXHR);
                });
            });
        },

        // Return Vidora's api recommendation uri
        getVidoraUri () {
            return this.$root.vidora_recommendation_url + "users/" + this.userId + "/recommendations?api_key=" + this.$root.vidora_api_key + "&category=" + this.category ;
        },

        // Return the css style of an item
        ratingStyle(item) {
            if (item.rating > -1) {
                return 'width: ' + item.rating / 5 * 100 + '%;';
            }
            return 'width:100%;';
        },

        // Initialise the userId
        setUserId() {
            return new Promise((resolve) => {
                // Initialise userId with thinkId OR vidora UUID
                const nk = Cookie.getCookie('nk');
                if (nk !== '' && nk !== null) {
                    this.userId = nk;
                } else {
                    this.userId = this.vidoraAPI.getUUID();
                }
                user.ifLoggedIn(profile => {
                        this.userId = profile.thinkId || this.userId;
                        resolve(this.userId);
                    },
                    () => {
                        resolve(this.userId);
                    });
            });
        },

        // Return filtered title
        getFilteredTitle(title) {
            return title.substring(0, 30);
        },

    },

    mounted() {
        this.getData();
    }
}
</script>
